@import '../download/bootstrap-4.4.1-dist/css/bootstrap.min.css';
@import '../download/elegant_font/HTML CSS/style.css';
@import '../download/font-awesome-4.7.0/css/font-awesome.min.css';
@import '../download/Icon_font_for_use/css/flaticon.css';
@import '../download/jquery-nice-select-1.1.0/css/nice-select.css';
@import '../download/jquery-ui-1.12.1/jquery-ui.min.css';
@import '../download/Magnific-Popup-master/dist/magnific-popup.css';
@import '../download/OwlCarousel2-2.3.4/dist/assets/owl.carousel.min.css';
@import '../download/sass/style';
@import '../download/SlickNav-master/dist/slicknav.min.css';
@import "style.css";
