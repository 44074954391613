	/*
  	Flaticon icon font: Flaticon
  	Creation date: 25/01/2019 11:32
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./fonts/Flaticon.eot");
  src: url("./fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./fonts/Flaticon.woff") format("woff"),
       url("./fonts/Flaticon.ttf") format("truetype"),
       url("./fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-001-luggage:before { content: "\f100"; }
.flaticon-002-breakfast:before { content: "\f101"; }
.flaticon-003-air-conditioner:before { content: "\f102"; }
.flaticon-004-fridge:before { content: "\f103"; }
.flaticon-005-tea:before { content: "\f104"; }
.flaticon-006-dumbbell:before { content: "\f105"; }
.flaticon-007-swimming-pool:before { content: "\f106"; }
.flaticon-008-heater:before { content: "\f107"; }
.flaticon-009-cctv:before { content: "\f108"; }
.flaticon-010-newspaper:before { content: "\f109"; }
.flaticon-011-no-smoking:before { content: "\f10a"; }
.flaticon-012-cocktail:before { content: "\f10b"; }
.flaticon-013-safety-box:before { content: "\f10c"; }
.flaticon-014-ring-bell:before { content: "\f10d"; }
.flaticon-015-door-hanger:before { content: "\f10e"; }
.flaticon-016-washing-machine:before { content: "\f10f"; }
.flaticon-017-sunbed:before { content: "\f110"; }
.flaticon-018-iron:before { content: "\f111"; }
.flaticon-019-television:before { content: "\f112"; }
.flaticon-020-telephone:before { content: "\f113"; }
.flaticon-021-flip-flops:before { content: "\f114"; }
.flaticon-022-clock:before { content: "\f115"; }
.flaticon-023-hair-dryer:before { content: "\f116"; }
.flaticon-024-towel:before { content: "\f117"; }
.flaticon-025-pillow:before { content: "\f118"; }
.flaticon-026-bed:before { content: "\f119"; }
.flaticon-027-mirror:before { content: "\f11a"; }
.flaticon-028-vacuum-cleaner:before { content: "\f11b"; }
.flaticon-029-wifi:before { content: "\f11c"; }
.flaticon-030-bathtub:before { content: "\f11d"; }
.flaticon-031-elevator:before { content: "\f11e"; }
.flaticon-032-armchair:before { content: "\f11f"; }
.flaticon-033-dinner:before { content: "\f120"; }
.flaticon-034-camera:before { content: "\f121"; }
.flaticon-035-eight-ball:before { content: "\f122"; }
.flaticon-036-parking:before { content: "\f123"; }
.flaticon-037-sink:before { content: "\f124"; }
.flaticon-038-five-stars:before { content: "\f125"; }
.flaticon-039-three-stars:before { content: "\f126"; }
.flaticon-040-key-card:before { content: "\f127"; }
.flaticon-041-lamp:before { content: "\f128"; }
.flaticon-042-menu:before { content: "\f129"; }
.flaticon-043-room-service:before { content: "\f12a"; }
.flaticon-044-clock-1:before { content: "\f12b"; }
.flaticon-045-door:before { content: "\f12c"; }
.flaticon-046-toothbrush:before { content: "\f12d"; }
.flaticon-047-calendar:before { content: "\f12e"; }
.flaticon-048-door-handle:before { content: "\f12f"; }
.flaticon-049-power-socket:before { content: "\f130"; }
.flaticon-050-bellboy:before { content: "\f131"; }